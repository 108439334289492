import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import LogoFull from '../components/LogoFull';
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <Header />
    <div>
        <div className="master-container">
          <main className="form-main" role="main">
            <div className="content-left d-flex flex-column justify-content-center align-items-center">
              <div className="content-left-inner d-flex flex-column align-items-center">
                <LogoFull></LogoFull>
                <h1 className='d-none'>Crafted</h1>
                <div id="main-text-container" className="text-center">
                  <p className="my-4 my-sm-4">Craft Brewers share tips and insights into their work</p>
                  <h3>This project is an indefinite hiatus and subscriptions are currently closed.</h3>
                  <p>Thank you for visiting and please <Link className="text-dark-blue" to="/editions">check out our past editions</Link>.</p>
                </div>
              </div>
            </div>
            <div className="background-right" />
          </main>
        </div>
        <div id="overlay">
          <div id="loader-object"></div>
        </div>
        <footer className="mastfoot mt-auto">
          <div className="inner">
          </div>
        </footer>
      </div>
    <Footer />
  </Layout>
);

export default IndexPage;
